import React from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';
import headerImage from '../../images/logos/smiley-rocks-logo-v1-rounded-nowords.png';
import version from '../../version.json';
import './Header.scss';

const closeNav = () => {
  const element = $('.navbar-collapse');
  if (element.hasClass('show')) {
    element.removeClass('show');
  }
};

const Header = () => (
  <header>
    <nav className="navbar navbar-expand-lg navbar-dark d-flex justify-content-between align-items-center px-4">
      <div className="d-flex justify-content-center align-items-center">
        <a className="navbar-brand" href="/">
          <img className="me-3" src={headerImage} height="50" alt="Smiley Rocks Logo" />
        </a>
        <h1 className="d-inline text-smiley-rocks-pink m-0 p-0 fw-bolder">
          Smiley Rocks
        </h1>
      </div>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#smileyRocksNavigation"
        aria-controls="smileyRocksNavigation"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse justify-content-end" id="smileyRocksNavigation">
        <div className="navbar-nav my-3">
          <NavLink
            to="/"
            className={({ isActive }) => {
              const baseCssClasses = "nav-item nav-link mx-2 p-2 me-5";
              return `${baseCssClasses}${isActive ? " active" : ""}`;
            }}
            onClick={closeNav}
          >
            Home
          </NavLink>
          <NavLink
            to="/add"
            className={({ isActive }) => {
              const baseCssClasses = "nav-item nav-link mx-2 p-2 me-5";
              return `${baseCssClasses}${isActive ? " active" : ""}`;
            }}
            onClick={closeNav}
          >
            Add Rock
          </NavLink>
          <NavLink
            to="/found"
            className={({ isActive }) => {
              const baseCssClasses = "nav-item nav-link mx-2 p-2 me-5";
              return `${baseCssClasses}${isActive ? " active" : ""}`;
            }}
            onClick={closeNav}
          >
            Found Rock
          </NavLink>
          <div className="d-md-none mt-4 mb-2">
            <div className="dropdown-divider" />
            <small className="ms-auto p-1">
              <span className="fw-bold me-2">
                Version:
              </span>
              {version.version}
            </small>
          </div>
        </div>
      </div>

    </nav>
  </header>
);

export default Header;