import React, { useState } from 'react';
import { toastError, toastSuccess } from '../../Toast';
import { addEmailList } from '../../../data';
import { emailValidation } from '../../../utils/validation';
import { getItem, setItem } from '../../../utils/localStorage';


const EmailListModal = (props) => {
  const [email, setEmail] = useState(null);
  const isValid = email && emailValidation(email) === true;
  const isInvalid = email && emailValidation(email) !== true;

  setItem('mailList', JSON.stringify({ lastShown: new Date() }));

  return (
    <div
      id="emailListModal"
      className="modal fade show d-block"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="emailListModalTitle"
      aria-hidden="false"
      style={{ color: 'black', background: 'rgba(0,0,0,0.5)' }}
    >
      <form onSubmit={async (e) => {
        e.preventDefault();
        const result = await addEmailList(email);
        if (result.success) {
          toastSuccess('Thank You! Your email adddress was added.');

          const mailList = JSON.parse(getItem('mailList') || '{}');
          mailList.signedUp = true;
          setItem('mailList', JSON.stringify(mailList));

          props.handleClose();
        } else {
          toastError('Unable to add your email 😔');
        }
      }}>

        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content box-shadow" style={{ background: 'rgba(255, 255, 255, 0.95)' }}>

            <div className="modal-header flex-column align-items-center">
              <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                <h1 className="text-smiley-rocks-pink modal-title fw-bold" id="emailListModalTitle">Join Our Email List</h1>
                <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={props.handleClose} />
              </div>
              <p className="pt-3">We have a ton of features planned, and we want to keep you posted! </p>
              <p>Join our email list and we can let you know when BIG features are available (don't worry, we will be respectful and you can unsubscribe at any time).</p>
            </div>

            <div className="modal-body">

              <div className="form-group mt-3">
                <input
                  id="emailListInput"
                  type="email"
                  className={`form-control form-control-lg${isValid ? ' is-valid' : ''}${isInvalid ? ' is-invalid' : ''}`}
                  aria-describedby="emailHelp"
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Email"
                />
                <small id="emailHelp" className="form-text text-muted font-italic">We'll never share your email with anyone else.</small>
                {isInvalid && <small id="emailError" className="invalid-feedback">{emailValidation(email)}</small>}
              </div>

            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-secondary btn-sm" data-dismiss="modal" onClick={props.handleClose}>No Thank You</button>
              <button type="submit" className="btn btn-smiley-primary btn-lg" disabled={!(email && emailValidation(email) === true)}>Add Email</button>
            </div>

          </div>
        </div>

      </form>
    </div>
  );
};

export default EmailListModal;