import { getDownloadURL, updateRock } from "../data";
import version from '../version.json';

export const fileRename = (file, newName) => {
  const blob = file.slice(0, file.size, file.type);
  return new File([blob], `${newName}${getFileExtension(file.type)}`, { type: file.type });
};

const getFileExtension = fileType => {
  switch (fileType) {
    case 'image/png':
      return '.png';
    case 'image/jpg':
      return '.jpg';
    case 'image/jpeg':
      return '.jpeg';
    case 'image/gif':
      return '.gif';
    case 'image/heic':
      return '.heic';
    default:
      return '.jpg';
  }
};

export const isDevelopment = () => {
  return process.env.NODE_ENV === 'development' || version.version.includes('beta');
};

export const minifyImage = async (rockId, file) => {
  // https://www.npmjs.com/package/image-resize-compress
  const [filename, fileExtension] = file.filename.split(/\.(?=[^.]+$)/);
  const photoUrl_minified = await getDownloadURL(`${filename}_400x400.${fileExtension}`, 'minified');
  await updateRock(rockId, {
    photoUrl_minified
  });
};