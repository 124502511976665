import React, { useState } from 'react';
import Title from '../../components/Title';
import { useForm } from 'react-hook-form';
import InputField from '../../components/Forms/InputField';
import { getRockInfo, addLocation } from '../../data';
import { useNavigate } from 'react-router-dom';
import { toastError, toastSuccess } from '../../components/Toast';

export default function Found() {
  const [location, setLocation] = useState(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
    getValues,
    trigger,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const submitFoundRock = async (data) => {
    // get rock to ensure it exists
    const rockResult = await getRockInfo(data.rockCode);
    if (!rockResult || !rockResult.success || rockResult.data.length === 0) {
      toastError(`Uh oh! Couldn't find that rock.`);
      return;
    }

    // grab first one
    const rock = rockResult.data[0];

    // add location where the rock was found
    const locationResult = await addLocation(rock.rockId, { latitude: location.coords.latitude, longitude: location.coords.longitude, type: 'FOUND' });
    if (!locationResult.success) {
      toastError(`Uh oh! Couldn't add that location info`);
      return;
    } else {
      toastSuccess(`Successfully added location for ${rock.rockName}`);
    }

    // redirect to
    navigate(`/add/share/${rock.shareId}`);
  };

  return (
    <div id="smiley-rocks-add-container" className="d-flex flex-column container">
      <Title>Found Rock</Title>
      <div style={{ background: 'rgba(255,255,255, 0.25)' }} className="container px-4 pt-3 pb-5 overflow-scroll no-scrollbar box-shadow">
        <form onSubmit={handleSubmit(submitFoundRock)} id="smiley-rocks-add-rock-form" className={`px-2`}>
          <div className="form-row mt-2 mb-4 flex-column">
            <label className="col-form-label-lg" htmlFor="inputRockCode">Rock Code</label>
            <InputField
              id="inputRockCode"
              {...register('rockCode', { required: true })}
              type="text"
              size="lg"
              value={getValues('rockCode')}
              touched={touchedFields.rockCode}
              error={errors.rockCode ? errors.rockCode.message : false} />
          </div>

          <div className="form-row mt-5 mb-4 flex-column">
            <div className="d-flex align-items-top">
              <InputField
                id="inputLocation"
                {...register('useLocation', {
                  required: false,
                  validate: (value) => {
                    if (value) {
                      if (location) {
                        return true;
                      } else {
                        navigator.geolocation.getCurrentPosition(position => {
                          setLocation(position);
                          trigger('useLocation');
                        });
                        return 'Please allow location services for this website, if you changed your mind... no problem uncheck this option';
                      }
                    } else {
                      location && setLocation(null);
                      return true;
                    }
                  }
                })}
                type="checkbox"
                parentclassName="pt-2"
                className="form-check-input"
                size="lg"
                touched={touchedFields.useLocation}
                error={errors.useLocation ? errors.useLocation.message : false} />
              <label className="col-form-label-lg form-check-label ms-2 p-0" htmlFor="inputLocation">Use Your Current Location as Rock Starting Location</label>
            </div>
            <small id="inputLocationHelp" className="form-text text-smiley-rocks-yellow-alt">Blank by Default, checking you will need to give permission to use your location</small>
          </div>

          <div className="form-row mt-5">
            <input className="btn btn-smiley-primary btn-lg" type="submit" disabled={!isValid} value="FOUND!" />
          </div>
        </form>
      </div>
    </div>
  );
};