import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useFileUpload } from 'react-firebase-file-upload';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { storage } from '../../utils/firebase';
import { toastError, toastSuccess } from '../Toast';
import { useStore } from '../../hooks/useStore';
import InputField from '../Forms/InputField';
import { getRocks, setRock, addLocation, updateRock } from '../../data';
import { emailValidation, rockNameValidation } from '../../utils/validation';
import { fileRename } from '../../utils/helpers';
import 'react-circular-progressbar/dist/styles.css';
import './AddRockForm.scss';

export default function AddRockForm(props) {
  const [location, setLocation] = useState(null);

  const disableLocation = true;

  const navigate = useNavigate();

  const fileUpload = useFileUpload(
    storage,
    {
      // the type of files to upload
      accept: 'image/png, image/jpg, image/jpeg, image/gif, image/heic, image/webp',
      // whether to accept multiple files or just one
      multiple: false,
      // where you want to save the uploaded files in firebase storage
      // path: 'test',
    });

  // props for file input
  const {
    /** Input type */
    type,
    /** Accepted file types (e.g. "image/png, image/jpeg") */
    // accept,
    /** Allow multiple files to be selected */
    // multiple,
    /** Disable input */
    // disabled,
    /** onChange event to set selected files */
    onChange,
    /** Selected files */
    files,
    /** Loading state */
    // loading,
    /** Error message */
    // error,
    /** Upload progress for each file */
    progress,
    /** Upload status for each file */
    // status,
    /** Download URL for each file */
    downloadURL,
    /** Upload complete state */
    // isCompleted,
    /** Upload files to firebase storage */
    onUpload,
    /** Reset states when finished uploading */
    onUploadComplete,
    /** Remove file from selected files */
    // onRemove
  } = fileUpload;

  const {
    register,
    handleSubmit,
    formState: { touchedFields, errors, isValid },
    getValues,
    trigger,
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { dispatch } = useStore();

  const completeReset = () => {
    reset();
    setLocation(null);
    onUploadComplete();
  };

  const handleResult = async (rock) => {
    if (rock.success) {
      toastSuccess('Yay! Your rock was added!');
      getRocks(false, dispatch);
      completeReset();
      navigate(`/add/share/${rock.data.shareId}`);
    } else {
      toastError(`Uh oh! THere was an issue.`);
    }
  };

  const submitRock = async data => {
    const { rockName, ownerEmail, useLocation } = data;

    // Create intial rock record
    const rockResult = await setRock({ rockName, ownerEmail });

    // Lets add the location information of the rock (if user decided to)
    const locationResult = (rockResult.success && useLocation && location)
      ? await addLocation(rockResult.data.rockId, { latitude: location.coords.latitude, longitude: location.coords.longitude })
      : { success: true };

    // Lets handle uploading the image (if user decided to)
    const imageResult = (rockResult.success && locationResult.success && files)
      ? await (async () => {
        try {
          files[0] = fileRename(files[0], rockResult.data.rockId);
          await onUpload();

          const returnCompleted = () => !!downloadURL;

          const waitUntil = (condition, checkInterval = 500) => new Promise(resolve => {
            let interval = setInterval(() => {
              console.log('CONDITION: ', condition());
              if (!condition()) return;

              clearInterval(interval);
              resolve();
            }, checkInterval);
          });

          await waitUntil(returnCompleted, 500);

          const downloadUrl = downloadURL[0];
          const updateRockResult = await updateRock(rockResult.data.rockId, {
            photoUrl: downloadUrl
          });

          return {
            success: updateRockResult.success,
          };
        } catch (error) {
          return { success: false, message: error };
        }
      })()
      : {
        success: true
      };

    rockResult.success && locationResult.success && imageResult.success && handleResult(rockResult);
  };

  return (
    <form onSubmit={handleSubmit(submitRock)} id="smiley-rocks-add-rock-form" className={`px-2${props.className ? ` ${props.className}` : ''}`}>

      <div className="form-row mt-2 mb-4 flex-column">
        <label className="col-form-label-lg" htmlFor="inputRockName">Rock Name</label>
        <InputField
          id="inputRockName"
          {...register('rockName', {
            required: true,
            validate: rockNameValidation,
          })}
          type="text"
          size="lg"
          value={getValues('rockName')}
          touched={touchedFields.rockName}
          error={errors.rockName ? errors.rockName.message : false} />
      </div>

      <div className="form-row mt-2 mb-4 flex-column">
        <label className="col-form-label-lg" htmlFor="inputEmail">Email</label>
        <InputField
          id="inputEmail"
          {...register('ownerEmail', {
            validate: (value) => (value === '' || emailValidation(value)),
            required: false,
          })}
          type="ownerEmail"
          size="lg"
          value={getValues('ownerEmail')}
          touched={touchedFields.ownerEmail}
          error={errors.ownerEmail ? errors.ownerEmail.message : false}
          help="Only needed for ownership tracking (future feature) - optional"
          placeholder="optional" />
      </div>

      {
        !disableLocation && navigator.geolocation
        && (
          <div className="form-row mt-5 mb-4 flex-column">
            <div className="d-flex align-items-top">
              <InputField
                id="inputLocation"
                {...register('useLocation', {
                  required: false,
                  validate: (value) => {
                    if (value) {
                      if (location) {
                        return true;
                      } else {
                        navigator.geolocation.getCurrentPosition(position => {
                          setLocation(position);
                          trigger('useLocation');
                        },
                          (error) => {
                            console.log('LOCATION ERROR: ', error);
                            errors.useLocation = {};
                            errors.useLocation.message = 'Please allow location services for this website, if you changed your mind... no problem uncheck this option';
                          }, {
                          enableHighAccuracy: true,
                          timeout: 5000,
                          maximumAge: 0
                        });
                      }

                      // return 'Please allow location services for this website, if you changed your mind... no problem uncheck this option';
                    } else {
                      location && setLocation(null);
                      return true;
                    }
                  }
                })}
                type="checkbox"
                parentclassName="pt-2"
                className="form-check-input"
                size="lg"
                touched={touchedFields.useLocation}
                error={errors.useLocation ? errors.useLocation.message : false} />
              <label className="col-form-label-lg form-check-label ms-2 p-0" htmlFor="inputLocation">Use Your Current Location as Rock Starting Location</label>
            </div>
            <small id="inputLocationHelp" className="form-text text-smiley-rocks-yellow-alt">Blank by Default, checking you will need to give permission to use your location</small>
          </div>
        )}

      <div className="form-row mt-5 mb-4 flex-column">
        <label className="col-form-label-lg d-block" htmlFor="inputRockImage">Rock Image</label>
        <input
          type={type}
          id="inputRockImage"
          onChange={onChange}
        // onChange={(event) => {
        //   const { target: { files } } = event;
        //   // event.target.files[0] = fileRename(files[0], rockResult.data.rockId);
        //   setRockImage(files[0]);
        //   onChange(event);
        // }}
        />

        {
          progress && Object.keys(progress)[0] && <div style={{ width: 100, margin: '0 auto' }}>
            <CircularProgressbar
              value={progress[Object.keys(progress)[0]]}
              text={`${progress[Object.keys(progress)[0]]}%`}
              background
              styles={buildStyles({
                // Colors
                pathColor: '#ffcc4d',
                textColor: '#ffcc4d',
                trailColor: 'rgba(255, 204, 77, 0.4)',
                backgroundColor: '#ff7892',
              })}
            />
          </div>
        }

      </div>

      <div className="form-row mt-5">
        <input className="btn btn-smiley-primary btn-lg" type="submit" disabled={!isValid} value="ADD" />
      </div>
    </form>
  );
}
