import React, { useState } from 'react';
import { useStore } from '../../hooks/useStore';
import { getItem } from '../../utils/localStorage';
import MailListModal from '../../components/Modals/EmailListModal';
import mainImage from '../../images/logos/smiley-rocks-logo-v1-rounded.png';
import './Main.scss';

const shouldWeShowEmailModal = () => {
  const mailList = JSON.parse(getItem('mailList') || '{}');
  if (mailList.lastShown) {
    const currentDate = new Date();
    const lastShownDate = new Date(mailList.lastShown);
    const oneDay = 24 * 60 * 60 * 1000;

    const daysSinceLastShown = Math.round(Math.abs((currentDate - lastShownDate) / oneDay));
    return daysSinceLastShown >= 7 && !mailList.signedUp;
  } else {
    return true;
  }
};

export default function Main() {
  const { state: { rocks } } = useStore();
  const [showEmailModal, setShowEmail] = useState(shouldWeShowEmailModal());

  console.log(`\n!!! ROCKS WE ARE CURRENTLY WORKING ON:   ${rocks.length} !!!\n\n`);

  return (
    <div id="smiley-rocks-default-container" className="container p-4">
      <img src={mainImage} alt="Smiley Rocks Main Logo" />
      {showEmailModal && <MailListModal handleClose={() => setShowEmail(false)} />}
    </div>
  );
};