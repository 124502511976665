
export function getItem(key) {
  try {
    const serializedState = localStorage.getItem(`smileyrocks.com:${key}`);
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
}

export function setItem(key, value) {
  localStorage.setItem(`smileyrocks.com:${key}`, JSON.stringify(value));
}

export function removeItem(key) {
  localStorage.removeItem(`smileyrocks.com:${key}`);
}