import { initializeApp } from 'firebase/app';
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { isDevelopment } from './helpers';

const development = isDevelopment();

const config = {
  apiKey: 'AIzaSyAQLWL8NIG2dYiKw7B0nUtAvdDAaCGFibA',
  authDomain: 'smiley-rocks.firebaseapp.com',
  databaseURL: 'https://smiley-rocks.firebaseio.com',
  projectId: 'smiley-rocks',
  storageBucket: 'smiley-rocks.appspot.com',
  messagingSenderId: '764723461050',
  timestampsInSnapshots: true,
};

const app = initializeApp(config);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const timestamp = () => serverTimestamp();

export let log = (event) => console.log('LOGGING FIREBASE EVENT', event);
if (!development) {
  console.log('CURRENTLY IN PRODUCTION!');
  console.log = () => { };
  const analytics = getAnalytics(app);
  log = (event) => logEvent(analytics, event);
} else {
  console.log('CURRENTLY IN DEVELOPMENT!');
}

log('notification_recieved');

