import initialState from './initialState';

const reducer = (state, action) => {
  switch (action.type) {
    case 'GET_ROCKS_APPROVED':
      return { ...state, rocks: action.payload };
    case 'GET_ROCKS_ALL':
      return { ...state, rocksAll: action.payload };
    case 'GET_ROCKS_PENDING_COUNT':
      return { ...state, rocksPendingCount: action.payload };
    default:
      return initialState;
  }
};

export default reducer;