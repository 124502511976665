import React from 'react';
import { Outlet } from 'react-router-dom';
import Title from '../../components/Title';

const Add = () => {

  return (
    <div id="smiley-rocks-add-container" className="d-flex flex-column container">
      <Title>Add Rock</Title>
      <div style={{ background: 'rgba(255,255,255, 0.25)' }} className="container px-4 pt-3 pb-5 overflow-scroll no-scrollbar box-shadow">
        <Outlet />
      </div>
    </div>
  );
};

export default Add;