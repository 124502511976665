import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where
} from "firebase/firestore";
import {
  db,
  storage,
  timestamp as getTimestamp
} from '../utils/firebase';
import initialState from '../hooks/useStore/initialState';

/**
 * Firebase Query Docs
 * https://firebase.google.com/docs/firestore/query-data/queries#web-version-9_3
 */

const COLLECTIONS = {
  rocks: 'rocks',
  locations: 'locations',
  email: 'emailList'
};

const defaultRockObject = {
  approved: null,
  ownerId: null,
  likes: 0,
  ownerEmail: null,
  photoUrl: null,
  rockId: null,
  rockName: null,
  shareId: null
};

export const getRocks = async (includeNotApproved = false, dispatch = null) => {
  try {
    const allRocks = [];
    const collectionRef = collection(db, COLLECTIONS.rocks);
    const snapshot = await getDocs(collectionRef);
    snapshot.forEach(rockDocument => allRocks.push(rockDocument.data()));
    const approvedRocks = allRocks.filter(rock => rock.approved);

    if (dispatch) {
      dispatch({ type: 'GET_ROCKS_APPROVED', payload: approvedRocks });
      dispatch({ type: 'GET_ROCKS_ALL', payload: allRocks });
      dispatch({ type: 'GET_ROCKS_PENDING_COUNT', payload: allRocks.reduce((accumulator, currentValue) => (!currentValue.approved ? accumulator + 1 : accumulator), 0) });
    }

    return includeNotApproved ? allRocks : approvedRocks;
  }
  catch (error) {
    console.log('Sorry! We had a problem getting the rocks:(\n', error);

    // Don't think we should clear out current rocks on an error
    // dispatch && dispatch({
    //   type: 'GET_ROCKS',
    //   payload: initialState.rocks
    // });

    return initialState.rocks;
  }
};

export const setRock = async (data, callback = () => null) => {
  let setRockData = ({ success: false, message: 'Unknown Error' });
  const timestamp = getTimestamp();
  try {
    const rockIdToUse = (await addDoc(collection(db, COLLECTIONS.rocks), {})).id || null;
    if (!rockIdToUse) { throw new Error('Unable to create rock'); }
    const rockShareId = rockIdToUse.substring(0, 6);
    const rockData = { createdAt: timestamp, ...defaultRockObject, ...data, rockId: rockIdToUse, shareId: rockShareId, updatedAt: timestamp };

    setRockData = await setDoc(doc(db, COLLECTIONS.rocks, rockIdToUse), rockData)
      .then(() => ({ success: true, message: 'Successfully Added!', data: rockData }))
      .catch(error => ({ success: false, message: `Error Saving: ${error}` }));
  } catch (error) {
    setRockData = ({ success: false, message: `Error Saving: ${error.message}` });
  }

  callback();
  return setRockData;
};

export const updateRock = async (rockId, data) => {
  let updateRockData = ({ success: false, message: 'Unknown Error' });
  const rockData = { ...data, updatedAt: getTimestamp() };

  try {
    updateRockData = await updateDoc(doc(db, COLLECTIONS.rocks, rockId), rockData)
      .then(() => ({ success: true, message: 'Successfully Updated!', data: rockData }))
      .catch(error => ({ success: false, message: `Error Saving: ${error}`, data: rockData }));
  } catch (error) {
    updateRockData = ({ success: false, message: `Error Saving: ${error.message}`, data: rockData });
  }

  return updateRockData;
};

export const getRockInfo = async (rockId = '') => {
  const allRocks = [];
  let getRockData = { success: false, message: 'Unknown Error', data: allRocks };

  try {
    const collectionRef = collection(db, COLLECTIONS.rocks);
    const snapshot = rockId.length < 10
      ? await getDocs(query(collectionRef, where('shareId', '==', rockId)))
      : await getDoc(collectionRef, rockId);
    snapshot.forEach(rockDocument => allRocks.push(rockDocument.data()));
    getRockData = { success: true, message: 'Successfully Fetched Data', data: allRocks };
  } catch (error) {
    getRockData.message = `Error Fetching: ${error.message}`;
  }

  return getRockData;
};

export const addLocation = async (rockId, location, callback = () => null) => {
  let addLocationData = ({ success: false, message: 'Unknown Error' });
  const locationData = {
    createdAt: getTimestamp(),
    location,
    rockId
  };

  try {
    addLocationData = await addDoc(collection(db, COLLECTIONS.locations), locationData)
      .then(() => ({ success: true, message: 'Successfully Updated!', data: locationData }))
      .catch(error => ({ success: false, message: `Error Saving: ${error}`, data: locationData }));
  } catch (error) {
    addLocationData = ({ success: false, message: `Error Saving: ${error.message}`, data: locationData });
  }
  callback();
  return addLocationData;
};

export const getDownloadURL = async (fileName, storageRef = '') => {
  let imageUrl = null;
  try {
    imageUrl = await storage
      .ref(storageRef)
      .child(fileName)
      .getDownloadURL();
  } catch (error) {
    console.log(`Error Getting URL:\n${error.message}`);
  }
  return imageUrl;
};

export const addEmailList = async (email, callback = () => null) => {
  let addEmailListData = ({ success: false, message: 'Unknown Error' });
  const timestamp = getTimestamp();
  const emailData = { createdAt: timestamp, email };

  try {
    addEmailListData = await addDoc(collection(db, COLLECTIONS.email), emailData)
      .then(() => ({ success: true, message: 'Successfully Added!', data: emailData }))
      .catch(error => {
        console.log('EMAIL ERROR: ', error);
        return { success: false, message: `Error Saving: ${error}` };
      });
  } catch (error) {
    console.log('EMAIL ERROR: ', error);
    addEmailListData = ({ success: false, message: `Error Saving: ${error.message}` });
  }

  callback();
  return addEmailListData;
};
