import React, { createContext, useReducer, useContext, useEffect } from 'react';
import initialState from './initialState';
import reducer from './reducer';
import { getRocks } from '../../data/';


export const StoreContext = createContext(initialState);

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const unsubscribe = async () => {
      getRocks(false, dispatch);
    };

    unsubscribe();
  }, []);

  return (
    <StoreContext.Provider value={{ state, dispatch }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
