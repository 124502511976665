import React from 'react';
import 'jquery';
import 'bootstrap';
import '@popperjs/core';
import { ToastContainer } from 'react-toastify';
import Header from './components/Header';
import Footer from './components/Footer';
import Routing from './components/Routing';
import 'react-toastify/dist/ReactToastify.min.css';

const App = () => (
  <div id="smiley-rocks-app-container">
    <Header />

    <div id="smiley-rocks-main-container" className="p-2 no-scrollbar">
      <Routing />
    </div>

    <Footer />

    <ToastContainer
      position="bottom-left"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      theme="colored"
    />
  </div>
);

export default App;
