import React from 'react';

export default React.forwardRef((props, ref) => {
  const {
    accept,
    className,
    type,
    touched,
    error,
    size,
    id,
    help,
    name,
    value,
    parentclassName = '',
    ...rest
  } = props;
  const isInvalid = touched && error;
  const isValid = touched && !error && value && value !== '';

  return (
    <div id={`${id}Container`} className={`d-flex flex-column${` ${parentclassName}`}`}>
      <input
        accept={accept || '*'}
        type={type || 'text'}
        id={`${id}Input`}
        className={className || `form-control${size ? ` form-control-${size}` : ''}${isInvalid ? ' is-invalid' : ''}${isValid ? ' is-valid' : ''}`}
        name={name}
        ref={ref}
        {...rest}
      />
      {help ? <small id={`${id || 'noId'}Help`} className="form-text text-smiley-rocks-yellow-alt">{help}</small> : null}
      {isInvalid ? <div className="alert alert-danger mt-1" role="alert">{error}</div> : null}
    </div>
  );
});