import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import MainView from '../../views/Main';
import AddView from '../../views/Add';
import FoundView from '../../views/Found';
import RockShareInfo from '../../components/ShareRock';
import AddRockForm from '../../components/AddRockForm';

export default function Routing() {
  return (
    <Routes>
      <Route path="add" element={<AddView />}>
        <Route path={`share/:rockShareId`} element={<RockShareInfo />} />
        <Route index element={<AddRockForm />} />
      </Route>
      <Route path="/found/*" element={<FoundView />} />
      <Route path="/*" element={<MainView />} />
    </Routes>
  );
};