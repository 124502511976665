import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getRockInfo } from '../../data';
import './ShareRock.scss';

const isDigit = (character) => {
  const regex = /^\d$/;
  return regex.test(character);
};

const getFormattedShareCode = code => (
  <span>
    {code.split('').map((character, index) => (
      isDigit(character)
        ? <span key={index} className="text-smiley-rocks-yellow letter-spacing">{character}</span>
        : <span key={index} className="letter-spacing" style={character === character.toUpperCase()
          ? {
            textDecorationStyle: 'double',
            textDecoration: 'underline'
          }
          : {
            textDecoration: 'none'
          }
        }>{character}</span>)
    )}
  </span>
);

const getRockStatus = status => {
  switch (status) {
    case true:
      return 'Approved';
    case false:
      return 'Rejected';
    default:
      return 'Pending';
  }
};

export default function ShareRock() {
  const [rockInfo, setRockInfo] = useState(null);
  const [rockFetched, setRockFetched] = useState(false);

  const { rockShareId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const rockData = await getRockInfo(rockShareId);

      if (rockData.success && rockData.data.length > 0) {
        setRockInfo(rockData.data[0]);
        setRockFetched(true);
      } else {
        setRockFetched(true);
      }
    })();
  }, [rockShareId]);

  const shareId = (rockInfo && rockInfo.shareId) ? rockInfo.shareId : '';

  return (
    <div id="smiley-rocks-share-info" className="d-flex flex-column">
      <h1 className="fw-bold text-smiley-rocks-yellow mb-4">Rock Share Information</h1>

      {!rockFetched && (
        <div className="d-flex justify-content-center">
          <div className="spinner-grow text-smiley-rocks-pink" role="status" style={{ width: 75, height: 75 }}>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {rockInfo
        ? (
          <div>
            <p><span className="label text-smiley-rocks-yellow-alt me-2">Name</span><span className="data">{rockInfo.rockName}</span></p>
            <p><span className="label text-smiley-rocks-yellow-alt me-2">Status</span><span className="data">{getRockStatus(rockInfo.approved)}</span></p>
            <p><span className="label text-smiley-rocks-yellow-alt me-2">Rock Code</span><span className="data">{rockInfo.shareId}</span></p>

            <div className="row" style={{ marginTop: 50 }}>
              <fieldset className="col-md-6">
                <legend className="h3 fw-bold">Share Instructions</legend>
                <ol>
                  <li>Write our web address <code className="text-smiley-rocks-yellow">smileyrocks.com</code> on the back of your rock</li>
                  <li>Carefully write your <code className="text-smiley-rocks-yellow">Rock Code</code> as well <span className="font-italic">(see example)</span></li>
                  <li>Spread some kindness & smiles and go place your rock safely for people to find</li>
                </ol>
                <span className="font-italic my-6">Please make sure to share us with your friends & family</span>
              </fieldset>
              <div className="col-md-6 d-flex justify-content-center align-items-center flex-grow-1">
                <div className="smiley-rock-share d-flex justify-content-center align-items-center">
                  <span className="smiley-rock-share-text mt-5">{getFormattedShareCode(shareId)}</span>
                </div>
              </div>
            </div>
            <div className="d-grid gap-2">
              <button type="button" className="btn btn-smiley-primary btn-lg mt-5" onClick={() => navigate('/add')}>ADD ANOTHER ROCK!</button>
            </div>
          </div>
        )
        : (
          <div>
            {rockFetched && <span className="font-italic">No Rocks Found for <span className="fw-bold">{rockShareId}</span> ...  😔</span>}
          </div>
        )
      }

    </div>
  );
}