import Filter from 'bad-words';
import emailValidator from 'email-validator';

export const emailValidation = email => emailValidator.validate(email) ? true : `Hmm... that email doesn't look right`;

export const rockNameValidation = (value) => {
  const filter = new Filter();
  if (!value || value.trim() === '') {
    return 'Rock Name is Required...';
  } else if (filter.isProfane(value)) {
    return "We don't really like bad words here...";
  }
  return true;
};