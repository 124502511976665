import React from 'react';
import version from '../../version.json';
import './Footer.scss';

const Footer = () => (
  <footer>
    <span className="p-1">&copy; {`2020-${new Date().getFullYear()}`} Smiley Rocks</span>
    <span className="py-1 px-2">|</span>
    <span className="p-1">All Rights Reserved.</span>
    <div className="d-none d-md-inline">
      <span className="py-1 px-2">|</span>
      <span className="p-1">Version:  {version.version}</span>
    </div>
  </footer>
);

export default Footer;